import {ChangeDetectionStrategy, Component, computed, inject, Input} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {CommonModule} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {LanguageIconDirective} from '@shared/shared-module/directives/language-icon.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {AuthenticationService} from 'src/app/core/services/authentication.service';
import {
  ADMIN_QUERY_DUTIES_PATH,
  ADMIN_QUERY_REQUESTS_PATH,
  CONTACTS_PATH,
  MILITARY_CAREER_PATH,
  PERSON_DATA_PATH
} from '../../../core/constants/url.const';
import {LanguageSelectorComponent} from '../language-selector/language-selector.component';

export interface NavigationMenuData {
  firstName: string;
  lastName: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    LanguageSelectorComponent,
    TranslateModule,
    LanguageIconDirective,
    SafeTranslatePipe,
    MatButtonModule
  ],
  selector: 'app-navigation-menu',
  standalone: true,
  styleUrl: 'navigation-menu.component.css',
  templateUrl: './navigation-menu.component.html'
})
export class NavigationMenuComponent {
  @Input() navigationMenuData: NavigationMenuData | null = null;
  protected readonly document = document;
  showMenu: boolean = false;

  public translate = inject(TranslateService);
  private authenticationService = inject(AuthenticationService);
  private featureFlagService = inject(FeatureFlagService);

  loggedIn = toSignal(this.authenticationService.isAuthenticated(), {initialValue: false});

  // implement link list
  linkList = [
    {
      label: 'i18n.navigation.item.duties',
      link: ADMIN_QUERY_DUTIES_PATH
    },
    {
      label: 'i18n.navigation.item.requests',
      link: ADMIN_QUERY_REQUESTS_PATH
    },
    {
      label: 'i18n.navigation.item.military-career',
      link: MILITARY_CAREER_PATH
    },
    {
      label: 'i18n.navigation.item.person-data',
      link: PERSON_DATA_PATH
    },
    {
      label: 'i18n.contacts.title',
      link: CONTACTS_PATH
    }
  ];

  public filteredLinkListByFeatureFlags = computed<{label: string; link: string}[]>(() => {
    if (!this.featureFlagService.hasFeaturesEnabled('contacts')()) {
      return this.linkList.filter(item => item.link !== CONTACTS_PATH);
    }
    return this.linkList;
  });

  logout(): void {
    this.document.getElementById('body')!.style['overflow'] = 'auto';
    this.showMenu = false;
    this.authenticationService.logout();
  }

  openMenu(): void {
    this.document.getElementById('body')!.style['overflow'] = 'hidden';
    this.showMenu = true;
  }

  closeMenu(): void {
    this.document.getElementById('body')!.style['overflow'] = 'auto';
    this.showMenu = false;
  }
}
