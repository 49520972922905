import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {EasterEggManager} from '@shared/shared-module/easter-egg/easter-egg.manager';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';

import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {CurrentMilitaryData} from '@shared/shared-module/stores/selectors/person-data.state.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateObjectPipe, SafeTranslatePipe, MatIconModule],
  selector: 'msa-user-banner',
  standalone: true,
  templateUrl: './user-banner.component.html'
})
export class UserBannerComponent {
  userBannerData = input.required<CurrentMilitaryData>();

  public easterEggManager = inject(EasterEggManager);

  getProgressBarValue(currentDutyDay: number, maxDutyDay: number) {
    return Math.floor((currentDutyDay * 100) / maxDutyDay);
  }
}
