import {NgModule} from '@angular/core';

import {MsaFooterComponent} from './msa-footer.component';
import {MsaRichFooterComponent} from './msa-rich-footer/msa-rich-footer.component';

@NgModule({
  imports: [MsaFooterComponent, MsaRichFooterComponent],
  exports: [MsaFooterComponent, MsaRichFooterComponent]
})
export class MsaFooterModule {}
