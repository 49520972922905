import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {TopBarComponent} from '@shared/shared-module/components/top-bar/top-bar.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {listenOnChildRouteData} from '@shared/shared-module/utils/routing.utils';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {PersonDataStateSelectors} from '../../stores/selectors/person-data.state.selectors';
import {NavigationMenuComponent} from '../navigation-menu/navigation-menu.component';
import {UserBannerComponent} from '../user-banner/user-banner.component';

@Component({
  selector: 'msa-header',
  templateUrl: './msa-header.component.html',
  imports: [
    CommonModule,
    UserBannerComponent,
    NavigationMenuComponent,
    TopBarComponent,
    TranslateModule,
    SafeTranslatePipe
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsaHeaderComponent {
  private router = inject(Router);

  userBannerData = readStoreSignal(PersonDataStateSelectors.getCurrentMilitaryData);
  navigationMenuData = readStoreSignal(PersonDataStateSelectors.getNavigationMenuData);
  hideNavigationAndUserBar = listenOnChildRouteData(this.router, 'hideNavigationBar');

  constructor(public translate: TranslateService) {}
}
