@if (loggedIn()) {
  <div class="content-wrapper">
    <div class="tw-flex tw-h-[92px] tw-items-center tw-justify-between d:tw-justify-start">
      <a class="tw-py-[20px] d:tw-mr-[136px] d:tw-py-[22.5px]" [routerLink]="['/']">
        <div class="tw-h-[36px] tw-w-auto d:tw-h-[47px]">
          <mat-icon class="!tw-h-full !tw-w-full" svgIcon="logo:armee" appLanguageIcon />
        </div>
      </a>
      <!-- Desktop view -->
      <nav class="tw-hidden tw-h-full tw-items-center tw-gap-60 d:tw-flex">
        @for (link of filteredLinkListByFeatureFlags(); track link.link) {
          <a
            class="tw-lead-xl tw-flex tw-h-full tw-items-center tw-border-b-[12px] tw-border-b-transparent tw-font-extra-bold d:tw-text-base"
            routerLinkActive="!tw-border-b-vermillion"
            routerLink="{{ link.link }}"
          >
            {{ link.label | translate }}
          </a>
        }
      </nav>
      <div class="d:tw-hidden" id="mobileMenuContainer">
        <button class="tw-flex tw-content-center" [attr.data-cy]="'toggleMenu'" (click)="openMenu()">
          <span class="tw-break-keep">{{ "i18n.common.menu" | safeTranslate }}</span>
          <mat-icon class="tw-ml-6">menu</mat-icon>
        </button>
        <div
          class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-10 tw-transform tw-bg-black tw-transition-transform tw-duration-500 tw-ease-in-out"
          [class]="showMenu ? 'tw-translate-y-0' : 'tw-translate-y-[-100vh]'"
        >
          <div class="tw-px-30 tw-py-15">
            <div class="tw-mb-30">
              <app-language-selector />
              <button
                class="tw-absolute tw-right-[3px] tw-top-[7px] tw-h-48"
                [attr.data-cy]="'toggleMenu'"
                (click)="closeMenu()"
              >
                <mat-icon class="!tw-h-48 !tw-w-48 tw-text-white" svgIcon="close" />
              </button>
            </div>
            <nav class="tw-flex tw-flex-col">
              @for (link of filteredLinkListByFeatureFlags(); track link.link) {
                <a
                  class="msa-text tw-relative tw-w-full tw-border-b tw-border-b-black-light-3 tw-py-15"
                  (click)="closeMenu()"
                  routerLinkActive="msa-nav-link-active tw-font-bold"
                  routerLink="{{ link.link }}"
                >
                  {{ link.label | translate }}
                </a>
              }
            </nav>
            <div class="msa-text-small tw-mt-30 tw-flex tw-content-center">
              <mat-icon class="tw-mr-6 tw-text-white" svgIcon="user" />
              <strong>
                {{ navigationMenuData?.firstName }}
                {{ navigationMenuData?.lastName }}
              </strong>
            </div>
            <div class="tw-mt-15">
              <button [attr.data-cy]="'logout'" (click)="logout()" color="primary" mat-flat-button>
                <span>{{ "i18n.common.logout" | safeTranslate }}</span>
                <mat-icon svgIcon="logout" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
