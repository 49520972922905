<a
  class="msa-link msa-text-tiny tw-flex tw-items-center tw-font-bold tw-text-white"
  data-cy="openNotifications"
  routerLink="/notifications"
>
  <div class="tw-m-6 tw-h-21 tw-w-21 tw-text-white d:tw-m-12 d:tw-h-24 d:tw-w-24">
    <mat-icon
      [inline]="true"
      [matBadgeHidden]="badgeHidden()"
      [matBadge]="countUnread()"
      matBadgeColor="accent"
      matBadgePosition="after"
      matBadgeSize="small"
      svgIcon="bell"
    />
  </div>
  <span class="tw-hidden d:tw-block">{{ "i18n.notification-list.notifications" | safeTranslate }}</span>
</a>
