<div
  class="tw-grid tw-grid-cols-1 tw-gap-[36px] tw-border-t tw-border-t-black-light-4 tw-px-15 tw-py-30 tw-text-black-light-5 d:tw-grid-cols-6 d:tw-gap-0 d:tw-pb-[66px] d:tw-pt-[85px]"
>
  <!-- contact -->
  <div class="msa-text-small tw-mt-30 tw-flex tw-flex-col tw-gap-12 d:tw-mt-0">
    <p class="tw-font-medium tw-text-white">
      {{ "i18n.common.contact" | safeTranslate }}
    </p>
    <ng-container *appFeatureFlag="'contactForm'; else contactFormDisabled">
      <a class="tw-w-fit" [href]="'tel:' + phone">{{ phone }}</a>
      <a class="tw-w-fit" target="_blank" routerLink="/contact" safeTranslate="i18n.further-services.contact-form"> </a>
      <a class="tw-w-fit" [href]="faqUrl()">FAQ</a>
    </ng-container>
    <ng-template #contactFormDisabled>
      <a class="tw-w-fit" [href]="'tel:' + phone">{{ phone }}</a>
      <a class="tw-w-fit" [href]="'mailto:' + email">{{ email }}</a>
    </ng-template>
  </div>

  <!-- Social Media -->
  <div class="msa-text-small tw-flex tw-w-fit tw-flex-col tw-gap-12">
    <p class="tw-font-medium tw-text-white">{{ "i18n.common.social-media.title" | safeTranslate }}</p>
    <div class="logo-container tw-flex tw-gap-12">
      <div>
        <a [href]="'i18n.common.social-media.facebook' | safeTranslate" target="blank">
          <mat-icon class="tw-size-[27px] tw-text-black-light-5" svgIcon="logo:facebook" />
        </a>
      </div>
      <div>
        <a [href]="'i18n.common.social-media.instagram' | safeTranslate" target="blank">
          <mat-icon class="tw-size-[27px] tw-text-black-light-5" svgIcon="logo:instagram" />
        </a>
      </div>
      <div>
        <a [href]="'i18n.common.social-media.youtube' | safeTranslate" target="blank">
          <mat-icon class="tw-size-[27px] tw-text-black-light-5" svgIcon="logo:youtube" />
        </a>
      </div>
      <div>
        <a [href]="'i18n.common.social-media.linkedin' | safeTranslate" target="blank">
          <mat-icon class="tw-size-[27px] tw-text-black-light-5" svgIcon="logo:linkedin" />
        </a>
      </div>
      <div>
        <a [href]="'i18n.common.social-media.tiktok' | safeTranslate" target="blank">
          <mat-icon class="tw-size-[27px] tw-text-black-light-5" svgIcon="logo:tiktok" />
        </a>
      </div>
    </div>
    @switch (language()) {
      @case ("de") {
        <a
          href="https://www.nlt.admin.ch/f/s.aspx?15330B86741542EC952535939CF8420932"
          target="_blank"
          safeTranslate="i18n.footer.subscribeNewsletter"
        ></a>
      }
      @case ("fr") {
        <a
          href="https://www.nlt.admin.ch/f/s.aspx?197747434A79863852EB99D7D8E65645F8"
          target="_blank"
          safeTranslate="i18n.footer.subscribeNewsletter"
        ></a>
      }
      @case ("it") {
        <a
          href="https://www.nlt.admin.ch/f/s.aspx?197962EAB1D988530962F9E9630356607E"
          target="_blank"
          safeTranslate="i18n.footer.subscribeNewsletter"
        ></a>
      }
    }
  </div>

  <!-- More army websites -->
  <div class="msa-text-small tw-flex tw-w-fit tw-flex-col tw-gap-12">
    <p class="tw-font-medium tw-text-white">{{ "i18n.common.more-army-websites" | safeTranslate }}</p>
    <p [innerHtml]="'i18n.common.website-administrative' | safeTranslate"></p>
    <p [innerHtml]="'i18n.common.website-vision-2030' | safeTranslate"></p>
    <p [innerHtml]="'i18n.common.website-leadership-campus' | safeTranslate"></p>
    <p [innerHtml]="'i18n.common.website-jobs' | safeTranslate"></p>
  </div>
</div>
