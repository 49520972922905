<button
  class="msa-link tw-flex !tw-h-full tw-flex-row tw-items-center !tw-bg-transparent"
  #menuOption="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  (menuOpened)="(menuOption.menuOpen)"
  (menuClosed)="(menuOption.menuOpen)"
  data-cy="openLanguageMenu"
  matButton
>
  <span class="msa-text-tiny tw-break-keep tw-font-bold">{{ currentLanguage() | uppercase }}</span>
  <mat-icon class="!tw-text-xl">
    {{ menuOption.menuOpen ? "expand_less" : "expand_more" }}
  </mat-icon>
</button>

<!-- contextual-obligation-menu -->
<mat-menu class="!tw-bg-black-light-8 !tw-shadow-lg" #menu="matMenu" yPosition="above">
  <div class="tw-flex tw-flex-col tw-text-black-light-3">
    @for (lang of translate.getLangs(); track lang) {
      <span
        class="msa-text-tiny tw-cursor-pointer tw-border-t tw-border-black-light-7 tw-p-18 tw-text-start hover:tw-bg-black-light-6.5"
        [class.tw-bg-black-light-7]="lang.toLowerCase() === currentLanguage().toLowerCase()"
        (click)="changeLanguage(lang)"
        data-cy="setLanguage"
      >
        {{ lang | uppercase }}
      </span>
    }
  </div>
</mat-menu>
