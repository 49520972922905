import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';

@Component({
  selector: 'msa-rich-footer',
  templateUrl: './msa-rich-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SafeTranslateModule, MatIconModule, FeatureFlagDirective, RouterLink]
})
export class MsaRichFooterComponent {
  private translateService = inject(TranslateService);
  private languageChanges = toSignal(this.translateService.onLangChange, {
    initialValue: {lang: this.translateService.currentLang, translations: []}
  });
  public language = computed(() => this.languageChanges().lang.toLowerCase());

  public faqUrl = computed(() => `https://www.armee.ch/${this.language()}${this.faq[this.language()]}`);

  public email = 'support@vtg.admin.ch';
  public phone = '+41 800 161 161';

  public faq: {[key: string]: string} = {
    de: '/faq',
    fr: '/faqf ',
    it: '/faqi'
  };
}
