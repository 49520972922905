import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {filter, map, shareReplay} from 'rxjs';

export const isNavigationEndEvent = (x: Event): x is NavigationEnd => x instanceof NavigationEnd;

export const anyChildRouteHasData = (route: ActivatedRoute, dataKey: string): boolean => {
  const shouldHideNavbar = route.snapshot.data?.[dataKey] ?? false;
  if (route.children?.length === 0) {
    return shouldHideNavbar;
  }
  return route.children.map(c => anyChildRouteHasData(c, dataKey)).some(Boolean) || shouldHideNavbar;
};

// this is a workaround to access the routes data
// we cannot access it via ActivatedRoute from angular, because this
// component does not live inside a <router-outlet>
// we must only check the route after navigation, only then the user is really routed away from a page
export const listenOnChildRouteData = (router: Router, dataKey: string, initialValue = false) => {
  return toSignal(
    router.events.pipe(
      filter(isNavigationEndEvent),
      map(() => anyChildRouteHasData(router.routerState.root, dataKey)),
      shareReplay(),
      takeUntilDestroyed()
    ),
    {initialValue}
  );
};
