<div class="tw-bg-black-light-1">
  <div
    class="content-wrapper tw-flex tw-h-[86px] tw-flex-row tw-items-center tw-justify-between tw-pt-30 d:tw-h-[46px] d:tw-pt-0"
  >
    <div class="tw-flex tw-flex-row tw-gap-12 d:tw-gap-[40px]">
      <a
        class="msa-link msa-text-tiny tw-font-bold tw-uppercase"
        href="{{ 'i18n.navigation-link.army.url' | safeTranslate }}"
        >{{ "i18n.navigation-link.army.text" | safeTranslate }}</a
      >
      <a
        class="msa-link msa-text-tiny tw-font-bold tw-uppercase"
        href="{{ 'i18n.navigation-link.recruitment.url' | safeTranslate }}"
        >{{ "i18n.navigation-link.recruitment.text" | safeTranslate }}</a
      >
      <a
        class="msa-link msa-text-tiny tw-font-bold tw-uppercase"
        href="{{ 'i18n.navigation-link.military-service.url' | safeTranslate }}"
        >{{ "i18n.navigation-link.military-service.text" | safeTranslate }}</a
      >
    </div>

    <div class="tw-flex tw-items-center tw-gap-6 d:tw-gap-21">
      @if (isLoggedIn()) {
        <div class="tw-pb-12 tw-pt-12">
          @if ((hasNoPersonData$ | async) || isLoggedIn()) {
            <button
              class="msa-link tw-flex !tw-h-full !tw-w-full tw-items-center !tw-bg-transparent"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="serviceNavigationMenu"
              data-cy="service-menu-user-button"
            >
              <mat-icon
                class="tw-me-6 !tw-h-21 !tw-w-21 tw-text-2xl d:!tw-h-24 d:!tw-w-24"
                [inline]="true"
                svgIcon="user"
              />

              <span class="msa-text-tiny tw-hidden tw-text-nowrap tw-font-bold d:tw-block">{{ userName() }}</span>
              <span class="text-nowrap msa-text-tiny tw-font-bold d:tw-hidden">{{ initials() | uppercase }}</span>

              <mat-menu class="!tw-bg-black-light-7 !tw-shadow-lg" #serviceNavigationMenu="matMenu" yPosition="below">
                <button class="!tw-min-w-0 !tw-p-18" (click)="logout()" data-cy="contextual-menu-logout" mat-menu-item>
                  <div class="tw-flex tw-items-center tw-gap-6 tw-text-black-light-3">
                    <span class="msa-text-tiny">{{ "i18n.common.logout" | safeTranslate }}</span>
                    <mat-icon class="!tw-h-18 !tw-w-18" svgIcon="logout" />
                  </div>
                </button>
              </mat-menu>
            </button>
          }
        </div>
      }

      @if (isLoggedIn() && (hasNoPersonData$ | async) === false) {
        <msa-notification-bell />
      }

      <app-language-selector
        class="tw-h-full d:tw-block"
        [class]="!isLoggedIn() || (hasNoPersonData$ | async) ? 'tw-block' : 'tw-hidden'"
      />
    </div>
  </div>
</div>
