import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatIconModule} from '@angular/material/icon';
import {Router, RouterLink} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {listenOnChildRouteData} from '@shared/shared-module/utils/routing.utils';

@Component({
  selector: 'msa-footer',
  templateUrl: './msa-footer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatIconModule, SafeTranslateModule, RouterLink]
})
export class MsaFooterComponent {
  currentDate: Date = new Date();

  private router = inject(Router);
  private translateService = inject(TranslateService);
  private languageChanges = toSignal(this.translateService.onLangChange, {
    initialValue: {lang: this.translateService.currentLang, translations: []}
  });
  public language = computed(() => this.languageChanges().lang?.toLowerCase() ?? this.translateService.currentLang);

  public hideSimpleContactFooter = listenOnChildRouteData(this.router, 'hideSimpleContactFooter');
}
